.select-login {
  width: 100%;
  height: 100vh;
  background-color: #d3d3d3;
  display: grid;
  place-content: center;
}

.select-login .wrapper-card {
  width: 600px;
  min-height: 300px;
  max-height: 400px;
  height: 100%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  padding: 25px;
}

.select-login .wrapper-card .card-content-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
}

.select-login .logo-content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.select-login .logo-content img {
  height: 36px;
}

.select-login .logo-content p {
  text-align: left;
  margin-left: 8px;
  line-height: 22px;
  margin-bottom: 0;
}

.select-login label {
  display: flex;
  cursor: pointer;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  margin-bottom: 0.375em;
}

.select-login label input {
  position: absolute;
  left: -9999px;
}

.select-login label input:checked + span {
  background-color: #335f42;
  color: white;
}

.select-login label input:checked + span:before {
  box-shadow: inset 0 0 0 0.4375em #036323;
}

.select-login label span {
  display: flex;
  align-items: center;
  padding: 0.375em 0.75em 0.375em 0.375em;
  border-radius: 8px;
  transition: 0.25s ease;
  color: #5f9671;
}

.select-login label span:hover {
  background-color: #d6d6e5;
}

.select-login label span:before {
  display: flex;
  flex-shrink: 0;
  content: "";
  background-color: #fff;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  margin-right: 0.375em;
  transition: 0.25s ease;
  box-shadow: inset 0 0 0 0.125em #005f20;
}

.select-login .btn-wrapper {
  display: flex;
  justify-content: end;
}

.select-login .btn-wrapper .login-btn {
  padding: 9px 12px;
  border-radius: 8px;
  color: #fff;
  background-color: #036323;
  border: none;
  font-weight: 500;
  cursor: pointer;
}

.select-login .langs-outer {
  display: flex;
  justify-content: end;
  align-items: center;
}

@media (max-width: 768px) {
  .select-login .wrapper-card {
    max-width: 600px;
    min-width: 300px;
    width: 100%;
    min-height: 300px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    padding: 25px;
  }

  .select-login .roow {
    flex-direction: column;
    margin-bottom: 0px;
  }
  .select-login .roow > div {
    width: 100%;
    flex-direction: column;
    margin-bottom: 15px;
  }
  .select-login label {
    font-size: 14px;
  }
  .select-login .roow input {
    height: 36px;
    font-size: 14px;
    padding: 5px;
  }
  .select-login .roow select {
    height: 36px;
    padding: 5px;
  }
  .select-login .btn-wrap button {
    width: 200px;
    height: 36px;
    font-size: 14px;
  }
  .select-login .about-product .left {
    width: calc(100% - 50px);
  }
  .select-login .about-product .left input {
    width: 32%;
    height: 36px;
    padding: 5px;
  }
  .select-login .about-product .left select {
    width: 32%;
    height: 36px;
    padding: 5px;
  }
  .select-loginl .about-product .right-btn {
    width: 36px;
    height: 36px;
  }
  .select-login .about-product .right-btn .icon {
    font-size: 18px;
  }

  .select-login .about-product .left input.name-input {
    width: 32%;
  }
}

.new-login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgba(249, 250, 251);
}
.new-login-content {
  max-width: 100%;
  width: 500px;
  display: flex;
  direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}
.new-login-content-title {
  max-width: 100%;
  width: 450px;
  display: flex;
  direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}
.new-login-content-title img {
  width: 100px;
}
.new-login-content-title p {
  text-align: center;
  font-weight: bold;
  line-height: 1.5;
}
.new-login-content-card {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  padding: 20px;
}
.buttons-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.login-button {
  border: 1px solid rgba(229, 231, 235);
  background-color: #fff;
  border-radius: 6px;
  cursor: pointer;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-weight: bold;
  font-size: 14px;
  line-height: 1;
  width: 100%;
}
.login-button.active {
  border: 1px solid #036323;
  background-color: #03632313;
}

.one-id,
.eri {
  width: 120px;
}
.foreign {
  width: calc(100% - 254px);
}
.submit {
  padding: 12px 20px;
  background: linear-gradient(
    135deg,
    rgb(120, 186, 139) -30%,
    rgb(93, 176, 117) 70%
  );
  color: #fff;
}

.login-button svg {
  width: 24px;
  height: 24px;
}
.bottom-buttons-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
}
@media (max-width: 768px) {
  .new-login-content {
    padding: 16px;
  }
  .buttons-wrapper {
    flex-wrap: wrap;
  }
  .login-button {
    width: 100%;
  }
}
